import { render, staticRenderFns } from "./chatRoom.vue?vue&type=template&id=7ca02a84&scoped=true&"
import script from "./chatRoom.vue?vue&type=script&lang=js&"
export * from "./chatRoom.vue?vue&type=script&lang=js&"
import style0 from "./chatRoom.vue?vue&type=style&index=0&id=7ca02a84&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ca02a84",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandXTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VBtn,VCard,VCol,VDivider,VExpandXTransition,VHover,VIcon,VProgressCircular,VRow,VSkeletonLoader,VSpacer,VTextField})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
