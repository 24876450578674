<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <v-divider></v-divider>
    <v-row no-gutters style="height: 87vh">
      <v-col
        v-if="!isMobile || active_chat == null"
        cols="12"
        md="4"
        style="height: 100%"
      >
        <div v-if="!loading">
          <div class="pa-2">
            <v-text-field
              class="rounded"
              outlined
              placeholder="Find chat"
              v-model="find"
              color="#0D47A1"
              dense
              prepend-inner-icon="mdi-magnify"
              hide-details
              @keyup="fetchData(false)"
            ></v-text-field>
            <div class="mt-4" v-for="(item, n) in chats" :key="n">
              <v-btn
                @click="getChatPerUser(item)"
                :color="active_chat == item.id ? '#697D85' : 'transparent'"
                depressed
                height="max-content"
                block
                class="pa-2 d-flex align-start text-left text-capitalize"
              >
                <v-avatar size="50" class="mr-3">
                  <img
                    :src="
                        item.user_id == id
                          ? item.to_profile
                            ? item.to_profile.avatar
                              ?item.to_profile.avatar
                              : ava
                            : ava
                          : item.to_user_id == id
                            ? item.profile
                              ? item.profile.avatar
                                ? item.profile.avatar
                                : ava
                              : ava
                            : ava
                    "
                    class="obj_fit"
                    style="background: white; padding: 1px"
                  />
                </v-avatar>
                <div style="flex-grow: 1">
                  <h4>
                      {{ item.user_id == id
                          ? item.to_profile
                            ? item.to_profile.nama
                            :'Nama kosong'
                          : item.to_user_id == id
                            ? item.profile
                              ? item.profile.nama
                              :'Nama kosong'
                            :'Nama kosong' 
                      }}
                  </h4>
                  <!-- <span class="font-weight-regular" style="font-size: x-small; color: red;" v-if="item.profile.account.deleted_at !=null">(Akun Deleted)</span> -->
                </div>
                <h4 class="not_bold" style="color: rgba(0, 0, 0, 0.3)">
                  {{
                    $date(
                      item.update_date
                    ).fromNow()
                  }}
                </h4>
              </v-btn>
            </div>
          </div>
          <div
            style="width: 100%; position: absolute; bottom: 10px; left: 10px"
          >
            <v-btn @click="addChat" depressed text class="text-capitalize">
              <v-icon class="mr-2">mdi-account-plus-outline</v-icon>
              Tambah Chat
            </v-btn>
          </div>
        </div>
        <div v-if="loading" class="pa-2">
          <v-skeleton-loader type="image" height="40px"></v-skeleton-loader>

          <div v-for="(item, n) in 3" :key="n" class="mt-3 d-flex align-center">
            <v-skeleton-loader
              width="20%"
              class="mr-2"
              type="avatar"
            ></v-skeleton-loader>
            <v-skeleton-loader
              width="100%"
              type="image"
              height="40px"
            ></v-skeleton-loader>
          </div>
        </div>
      </v-col>
      <v-divider v-if="!isMobile" vertical></v-divider>
      <v-col
        cols="12"
        md="8"
        v-if="(isMobile && active_chat != null) || !isMobile"
        style="border-left: 1px solid #e0e0e0"
      >
        <div v-if="active_chat != null" style="height: 100%; width: 100%">
          <div class="pa-2 d-flex align-center" style="height: 8vh">
            <v-avatar size="40" class="mr-3">
              <img
                :src=" selectChat.user_id == id
                          ? selectChat.to_profile
                            ? selectChat.to_profile.avatar
                              ?selectChat.to_profile.avatar
                              : ava
                            : ava
                          : selectChat.to_user_id == id
                            ? selectChat.profile
                              ? selectChat.profile.avatar
                                ? selectChat.profile.avatar
                                : ava
                              : ava
                            : ava
                "
                class="obj_fit"
                style="background: white; padding: 1px"
              />
            </v-avatar>
            <h4 class="mr-2">
              {{ selectChat.user_id == id
                  ? selectChat.to_profile
                    ? selectChat.to_profile.nama
                    :'Nama kosong'
                  : selectChat.to_user_id == id
                    ? selectChat.profile
                      ? selectChat.profile.nama
                      :'Nama kosong'
                    :'Nama kosong' 
              }}
            </h4>
            <!-- <v-tooltip bottom v-if="dataSet[active_chat].profile.account.deleted_at !=null">
              <template v-slot:activator="{ on, attrs }">
                <span 
                  v-bind="attrs"
                  v-on="on" 
                  class="font-weight-regular" 
                  style="font-size: x-small; color: red;" 
                  v-if="dataSet[active_chat].profile.account.deleted_at !=null">
                  (Akun Deleted)
                </span>
              </template>
              <span>Akun sudah dihapus, tidak bisa kirim pesan!!</span>
            </v-tooltip> -->
            <v-spacer></v-spacer>
            <v-btn icon v-if="isMobile" @click="active_chat = null">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <input
              style="visibility: hidden; position: absolute; z-index: -1"
              ref="file"
              type="file"
              @change="onFilePicked"
            />
          </div>
          <v-divider></v-divider>
          <div class="temp_chat">
            <div
              class="pa-2"
              v-if="openChats"
              id="scroll-target"
              style="max-height: 77vh"
            >
              <div>
                <div
                  v-scroll:#scroll-target="onScroll"
                  v-for="(item, n) in openChats"
                  :key="n"
                >
                  <div :class="item.user_id == id ? 'my_temp' : 'your_temp'">
                    <div
                      :class="
                        item.user_id == id
                          ? 'd-flex align-end justify-center flex-column'
                          : 'd-flex align-start justify-center flex-column'
                      "
                      style="width: 100%"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-card
                          class="px-1 py-2"
                          :color="item.user_id == id ? '#052633' : '#CDD0D1'"
                          max-width="50%"
                          :style="`border-radius:${
                            item.user_id == id
                              ? '15px 15px 0px 15px'
                              : '15px 15px 15px 0px'
                          } `"
                        >
                          <div v-if="item.replay">
                            <div
                              :class="
                                item.user_id == id ? 'my_reply' : 'ur_reply'
                              "
                            >
                              <b class="name_sender">
                                {{ item.replay.user_id == id
                                    ? item.replay.to_profile
                                      ? item.replay.to_profile.nama
                                      :'Nama kosong'
                                    : item.replay.to_user_id == id
                                      ? item.replay.profile
                                        ? item.replay.profile.nama
                                        :'Nama kosong'
                                      :'Nama kosong' 
                                }}
                              </b>
                              <p class="ma-0 default_ limit2_txt">
                                {{  item.replay.is_file
                                      ? item.replay.file
                                      : item.replay.text
                                }}
                              </p>
                            </div>
                          </div>
                          <p
                            class="ma-0 px-2"
                            :style="`color:${
                              item.user_id == id ? 'white' : 'black'
                            }; text-decoration: ${
                              item.is_file? 'underline' : ''
                            };cursor:${item.is_file? 'pointer' : ''};`"
                            @click="
                              item.is_file? downloadFile(item.file) : null
                            "
                          >
                            {{ item.is_file? item.file : item.text }}
                          </p>
                          <div
                            class="d-flex align-center px-2"
                            style="min-width: 150px !important"
                          >
                            <p
                              class="ma-0 small_txt text-left"
                              :style="`color:${
                                item.user_id == id ? '#E0E0E0' : '#757575'
                              };`"
                            >
                              {{ $date(item.date).format("H:mm") }}
                            </p>
                            <v-spacer></v-spacer>

                            <div
                              class="d-flex justify-space-between align-center"
                              style="height: 28px"
                            >
                              <v-expand-x-transition>
                                <div class="d-flex justify-end" v-if="hover">
                                  <v-card
                                    class="d-flex align-center"
                                    color="#f6f6f6"
                                    flat
                                  >
                                    <v-btn
                                      icon
                                      small
                                      @click="replyChat(item)"
                                    >
                                      <v-icon class="mr-1" small
                                        >mdi-reply</v-icon>
                                    </v-btn>
                                    <v-btn
                                      icon
                                      small
                                      v-if="item.user_id == id"
                                      @click="deleteData(item)"
                                    >
                                      <v-icon class="mr-1" small
                                        >mdi-delete</v-icon
                                      >
                                    </v-btn>
                                  </v-card>
                                </div>
                              </v-expand-x-transition>
                            </div>
                          </div>
                        </v-card>
                      </v-hover>
                    </div>
                  </div>
                </div>
                <div
                  v-if="send_chat"
                  :class="
                    send_chat
                      ? 'mt-2 pa-2 d-flex align-center justify-end'
                      : 'mt-2 pa-2 d-flex align-center justify-start'
                  "
                  style="height: 3vh"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </div>
              <div class="mt-2 temp_reply pa-2" v-if="dataReply">
                <v-btn
                  icon
                  color="#6FA287"
                  class="mt-1 mr-2"
                  @click="dataReply = null"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <div class="strip_rep">
                  <b class="name_sender">
                    {{ dataReply.user_id == id
                          ? dataReply.to_profile
                            ? dataReply.to_profile.nama
                            :'Nama kosong'
                          : dataReply.to_user_id == id
                            ? dataReply.profile
                              ? dataReply.profile.nama
                              :'Nama kosong'
                            :'Nama kosong' 
                      }}
                  </b>
                  <p class="default_ limit_txt">
                    {{ dataReply.user_id == id
                          ? dataReply.to_profile
                            ? dataReply.to_profile.is_file
                            :'Nama kosong'
                          : dataReply.to_user_id == id
                            ? dataReply.profile
                              ? dataReply.profile.is_file
                              :'Nama kosong'
                            :'Nama kosong' 
                      }}
                    {{
                      dataReply.is_file
                        ? dataReply.file
                        : dataReply.text
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-2 mt-2 d-block" style="height: 5vh">
            <v-text-field
              :disabled="send_chat"
              ref="typist"
              v-if="!file"
              v-model="chat_new"
              hide-details
              solo
              dense
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Type a message"
              prepend-icon="mdi-attachment"
              append-outer-icon="mdi-send-outline"
              :append-icon="chat_new ? 'mdi-close' : ''"
              @click:append="chat_new = ''"
              @click:append-outer="sendMessageChat"
              @click:prepend="selectFile"
              @keyup.enter="sendMessageChat"
            ></v-text-field>
            <v-text-field
              :disabled="send_chat == false && url_file == ''?true:false"
              ref="typist"
              v-if="file"
              readonly
              :value="file.name"
              hide-details
              dense
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Type a message"
              prepend-icon="mdi-attachment"
              :append-icon="file ? 'mdi-close' : ''"
              append-outer-icon="mdi-send-outline"
              @click:append="file = null; url_file = ''"
              @click:append-outer="sendMessageChat"
              @click:prepend="selectFile"
            ></v-text-field>
          </div>
        </div>
        <div
          v-if="!isMobile && active_chat == null"
          class="d-flex align-center justify-center flex-column"
          style="width: 100%; height: 100%"
        >
          <div
            class="pa-2 d-flex align-center justify-center flex-column"
            style="height: 92vh; width: 100%"
          >
            <div
              class="d-flex align-center justify-center"
              style="width: 45%; height: 45%"
            >
              <img
                src="../../assets/img/photo Login.png"
                alt="logo-login"
                height="100%"
              />
            </div>
            <h4 class="not_bold my-5">Pilih Chat Terlebih dahulu</h4>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- <v-row no-gutters>
      <v-col cols="4">
        <div v-for="(chat, n) in chats" :key="n">
          <v-btn full-width @click="getChatPerUser(chat)" :class="chat.name == 'ben' ? 'client1' : 'client2'">
              <small>{{ chat.name }}</small> <br>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="8">
        <div v-if="selectChat.length != 0">
          <div>{{ selectChat?selectChat.name:"" }}</div>
          <div v-for="(chat, n) in openChats" :key="n">
            <div :class="chat.user_id == 1?'client1':'client2'">
              <strong>{{ chat.text }}</strong>
            </div>
          </div>
          <v-text-field
              v-model="chat_new"
              hide-details
              solo
              dense
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Type a message"
              prepend-icon="mdi-attachment"
              append-outer-icon="mdi-send-outline"
              :append-icon="chat_new ? 'mdi-close' : ''"
              @click:append="chat_new = ''"
              @click:append-outer="sendMessageChat"
              @click:prepend="selectFile"
              @keyup.enter="sendMessageChat"
            ></v-text-field>
        </div>
      </v-col>
    </v-row> -->
    <dialog-medium
      :d_medium="d_create"
      :type="d_type"
      @close="d_create = false"
      :title="d_title"
      :inventory="inventory"
      :line="line"
      @refetch="fetchData"
    />
    <dialog-small
      :d_small="d_small"
      @refetch="fetchData"
      @close="d_small = false"
      :inventory="inventory"
      :type="d_type"
      :title="d_title"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavbarProfile from "../../components/navbarProfile/navbarProfile";
import {getFirestore, onSnapshot, collection, doc, setDoc, orderBy, query, where, or, and, getDocs, getDoc, updateDoc} from "firebase/firestore";

export default {
  components: { NavbarProfile},
  name: "chatRoom",
  computed: {
    ...mapState({
      drawer: (state) => state.drawer,
      profile: (state) => state.profile,
      id: (state) => state.id,
      ava: (state) => state.ava,
      isMobile: (state) => state.isMobile,
      role: (state) => state.role,
    }),
  },
  data() {
    return {
      username: '',
      chats: [],
      openChats:[],
      selectChat:[],
      chat_new:'',
      url_file:'',
      idSubThreadPerChat: '',
      find: "",
      active_chat: null,
      loading: true,
      send_chat: false,
      new_message: "",
      file: null,
      dataSet: null,
      offsetTop: 0,
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      hover: false,
      dataReply: "",
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Chat Room",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  async created() {
    // fungsi web socket
    // window.Echo.channel("chat").listen("MessageEvent", (event) => {
    //   if (event.message) {
    //     if (event.type != "delete") {
    //       this.dataReply = null;
    //       if (event.message.replay != null) {
    //         event.message.replay = JSON.parse(event.message.replay);
    //       }
    //       if (this.dataSet == null) {
    //         this.fetchData(true);
    //       } else {
    //         for (let i = 0; i < this.dataSet.length; i++) {
    //           if (
    //             (event.message.user_id == this.dataSet[i].to_user_id &&
    //               event.message.to_user_id == this.dataSet[i].user_id) ||
    //             (event.message.user_id == this.dataSet[i].user_id &&
    //               event.message.to_user_id == this.dataSet[i].to_user_id)
    //           ) {
    //             this.dataSet[i]["data"].push(event.message);
    //           }
    //         }
    //         let fetchData_user_id = false;
    //         for (let i = 0; i < this.dataSet.length; i++) {
    //           if (this.dataSet[i].user_id != event.message.user_id) {
    //             fetchData_user_id = true;
    //             break;
    //           }
    //         }
    //         let fetchData_to_user_id = false;
    //         for (let i = 0; i < this.dataSet.length; i++) {
    //           if (this.dataSet[i].to_user_id != event.message.to_user_id) {
    //             fetchData_to_user_id = true;
    //             break;
    //           }
    //         }
    //         if (fetchData_user_id || fetchData_to_user_id) {
    //           this.fetchData(false);
    //         }
    //         if (this.role) {
    //           this.scrollDown(true);
    //         }
    //       }
    //     } else {
    //       this.dataReply = null;
    //       this.fetchData(false);
    //     }
    //   }
    //   this.send_chat = false;
    // });
    // fungsi web socket

  },
  mounted() {
    let roleInterval = setInterval(() => {
      if (this.role && this.id) {
        this.fetchData(true);
        clearInterval(roleInterval);
      }
    }, 500);
    console.log(new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1]);
  },
  methods: {
    replyChat(chat) {
      this.dataReply = chat;
      this.onFocused();
    },
    deleteData(item) {
      this.inventory = {
        data: item,
        path: this.selectChat,
        deleteType: "Chat",
      };
      this.d_type = "delete";
      this.d_title = "Delete Chat";
      this.d_small = true;
      this.line = true;
    },
    onFocused() {
      this.scrollDown(true);
      setTimeout(() => {
        this.$refs["typist"].$refs.input.focus();
      }, 100);
    },
    downloadFile(link) {
      window.open(link, "blank");
    },
    addChat() {
      this.inventory = this.profile;
      this.d_type = "add_chat";
      this.d_title = "Tambah Chat";
      this.d_create = true;
      this.line = true;
    },
    scrollDown(click) {
      if (this.active_chat != null) {
        setTimeout(function () {
          if (this.role || click) {
            let down = document.querySelector(".temp_chat");
            down.scrollTop = down.scrollHeight; //auto scroll bottom of page
            window.scrollTo({
              left: 0,
              top: document.body.scrollHeight + 3000,
              behavior: "smooth",
            });
          }
        }, 200);
      }
    },
    async fetchData(first) {
      if (first) {
        this.loading = true;
      }
      // let data = {
      //   path: `sysadmin/customer-services?filter[nama]=${this.find}`,
      // };
      // this.$store
      //   .dispatch("fileData/getData", data)
      //   .then((data) => {
      //     if (data.status == "success") {
      //       data.data.forEach((item) => {
      //         item.data.forEach((chat) => {
      //           if (chat.replay != null) {
      //             chat.replay = JSON.parse(chat.replay);
      //           }
      //         });
      //       });
      //       this.dataSet = data.data;
      //       console.log(this.dataSet);
      //       if (first) {
      //         this.active_chat = null;
      //       }
      //       this.dataReply = null;
      //     }
      //     this.loading = false;
      //   })
      //   .catch((e) => {
      //     this.loading = false;
      //     this.$store.dispatch("notify", {
      //       msg: e.message,
      //       type: "error",
      //     });
      //   });
      const q = query(collection(getFirestore(), "chats"), or( where("user_id", "==", parseInt(this.id)),where('to_user_id', '==', parseInt(this.id))), orderBy('date','desc'));

      onSnapshot(q, (querySnapshot) => {
        this.chats = [];
        querySnapshot.docs.map((doc) => {
          this.chats.push(doc.data());
        })
      });
      this.loading = false;

    },
   async getChatPerUser(chat){
      this.active_chat = chat.id;
      this.dataReply = null;
      this.selectChat = chat;
      const q = query(collection(getFirestore(), "chats/"+chat.id+"/messages"), orderBy('date','asc'));

      onSnapshot(q, async(querySnapshot) => {
      this.openChats = [];
      querySnapshot.docs.map(async(doc) => {
          this.openChats.push(doc.data());
        })
      });
      this.scrollDown(true);
    },
    async sendMessageChat(){
      this.scrollDown(true);
      if(this.chat_new || this.url_file){
        this.send_chat = true;
        let user_id = this.selectChat.user_id;
        let to_user_id = this.selectChat.to_user_id;
        let nama = this.selectChat.profile.nama;
        let klien_id = this.selectChat.profile.klien_id;
        if(this.selectChat.to_user_id == this.id){
          user_id = this.selectChat.to_user_id;
          to_user_id = this.selectChat.user_id;
          nama = this.selectChat.to_profile.nama;
          klien_id = this.selectChat.to_profile.klien_id;
        }
        await this.randomIdChat();

        setDoc(doc(getFirestore(), "chats/"+this.selectChat.id+"/messages",this.idSubThreadPerChat.toString()),{
          id:this.idSubThreadPerChat,
          user_id:user_id,
          to_user_id:to_user_id,
          text:this.chat_new,
          replay:this.dataReply,
          to_profile: this.selectChat.to_profile,
          profile: this.selectChat.profile,
          is_file:this.url_file!=''?true:false,
          file:this.url_file,
          date:Date.now(),
        });
        await updateDoc(doc(getFirestore(), "chats", this.selectChat.id), {
            update_date:Date.now(),
        });
        this.scrollDown(true);
      
        await this.randomIdChat();
        setDoc(doc(getFirestore(), "notification/"+this.idSubThreadPerChat.toString()),{
          id: this.idSubThreadPerChat,
          sender: user_id,
          recipient: to_user_id,
          text: nama + ' mengirimi Anda sebuah pesan',
          text_html:'<b>' + nama + '</b> mengirimi Anda sebuah pesan',
          url_page: '/admin/mk/chat-room',
          is_read: false,
          klien_id: klien_id,
          date: Date.now(),
        });
        this.chat_new = '';
        this.file = '';
        this.url_file = '';
        this.dataReply = null;
        this.send_chat = false;
      }
    },
    async randomIdChat(){
      let id = Math.random().toString(13).slice(2);
      
      let docRef = doc(getFirestore(), "chats/"+this.selectChat.id+"/messages/"+id.toString());
      let docSnap = await getDoc(docRef);
      
      do {
          id = Math.random().toString(13).slice(2);
          docRef = doc(getFirestore(), "chats/"+this.selectChat.id+"/messages/"+id.toString())
          docSnap = await getDoc(docRef);
      } while (docSnap.exists()){
        this.idSubThreadPerChat = id;
      }
    },
    changeChat(idx) {
      this.active_chat = idx;
      this.scrollDown(true);
      this.dataReply = null;
    },
    sendMessage() {
      this.send_chat = true;
      this.scrollDown(true);
      let body = [
        {
          message: this.file ? this.file : this.new_message,
        },
        {
          to_user_id: this.dataSet[this.active_chat].user_id,
        },
        {
          file: this.file,
        },
        {
          is_file: this.file ? 1 : 0,
        },
      ];
      if (this.dataReply) {
        body = [
          {
            message: this.file ? this.file : this.new_message,
          },
          {
            to_user_id: this.dataSet[this.active_chat].user_id,
          },
          {
            file: this.file,
          },
          {
            is_file: this.file ? 1 : 0,
          },
          {
            replay: JSON.stringify(this.dataReply),
          },
        ];
      }
      if (!body[2].file) {
        body.splice(2, 1);
      }
      let data = {
        body: body,
        path: `sysadmin/customer-services/send-chat`,
      };
      this.$store
        .dispatch("fileData/postApi", data)
        .then((data) => {
          if (data.status == "success") {
            this.new_message = "";
            this.file = null;
          } else {
            this.send_chat = false;
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          this.send_chat = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
    },
    selectFile() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      if (files.length > 0) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
        let body = [{
          file: files[0],
        }];
        let data = {
          body: body,
          path: `sysadmin/upload/file-chat`,
        };
        this.$store
          .dispatch("fileData/postApi", data)
          .then((data) => {
            if (data.status == "success") {
              this.url_file = data.url;
              this.file = files[0];
            } else {
              var key = Object.keys(data.data.errors);
              var values = Object.values(data.data.errors);
              if (key.length == 0) {
                this.$store.dispatch("notify", {
                  msg: data.message,
                  type: "error",
                });
              }
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            }
          })
          .catch((e) => {
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: e.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          });
      } else {
        this.file = null;
      }
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
  },
};
</script>

<style scoped>
.my_temp {
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  transition: 0.5s;
  /* margin-bottom: 10px; */
  border-radius: 5px;
  overflow-y: auto;
}
.your_temp {
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  transition: 0.5s;
  border-radius: 5px;
  overflow-y: auto;
}
.temp_chat {
  height: 77vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.temp_reply {
  display: flex;
  width: 100%;
  background: #f6f6f6;
}
.strip_rep {
  border-left: solid 3px #106967;
  padding: 2px 10px;
}
.name_sender {
  font-size: 13px;
  color: #106967;
}
.default_ {
  color: #7f7f7f;
  font-size: small;
  margin-bottom: 0 !important;
}
.limit_txt {
  width: 400px;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.limit2_txt {
  max-width: 300px;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.my_reply {
  word-break: break-all;
  white-space: pre-wrap;
  padding: 5px;
  cursor: pointer;
  border-left: solid 3px #fff;
}
.ur_reply {
  word-break: break-all;
  white-space: pre-wrap;
  padding: 5px;
  cursor: pointer;
  background: #e4e3e3;
  border-left: solid 3px #006341;
  border-radius: 0 10px 0 0;
}
@media (min-width: 500px) {
  .limit_txt {
    width: 400px;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .limit2_txt {
    max-width: 300px;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media (min-width: 375px) {
  .limit_txt {
    width: 200px;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .limit2_txt {
    max-width: 100px;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .client1{
    background: blue;
    margin: 10px;
    padding: 10px;
    text-align: left;
  }
  .client2{
    background: pink;
    padding: 10px;
    margin: 10px;
    text-align: right;
  }
}
</style>
